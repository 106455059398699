<template>
    <q-layout view="lHh LpR lFf">
        <q-page-container style="height: 100vh">
            <router-view />
        </q-page-container>
    </q-layout>
</template>

<script>
export default {
  data () {
    return {
      left: false
    }
  }
}
</script>
